<script setup>
import TextViewerVue from '@/components/TextViewer.vue'
import LoadingVue from '@/components/ui/Loading.vue'
import { getQna, getQnaReply, deleteQna } from '@/api/platform/community'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import LanguageConverter from '@/util/LanguageConverter'
import { isShowQnaWriter } from './common'

const app = getCurrentInstance()
const getDate = app.appContext.config.globalProperties.$getDate

const store = useStore()
const route = useRoute()
const router = useRouter()
const qna = ref(null)
const isLoading = ref(false)
const isWriteUser = ref(false)
const boardData = ref({
  boardName: 'qna',
  boardId: 0
})
const isPassed = ref(false)

const numberFormat = (num) => {
  return new Intl.NumberFormat().format(num)
}

const routeReplace = () => {
  const path = route.path
  const listUrl = path.slice(0, path.lastIndexOf('/'))
  router.replace(listUrl)
}

const mapData = async (item) => {
  const reply = await getQnaReply(item.qnaId)

  return {
    ...item,
    date: getDate(item.createdAt),
    readCount: item.qnaViewCount,
    content: item.content === ' ' ? '문의내용이 없습니다.' : item.content,
    reply: reply.code === 'DB_DATA_NOT_FOUND' ? '아직 답변이 없습니다.' : reply[0]
  }
}

const loadQna = async (id) => {
  const qnaData = await getQna(id)
  return mapData(qnaData)
}

const user = store.state.userStore.info
const checkWriteUser = (qna) => {
  return user.groupName === 'admin' || user.userId === qna.userId
}
const checkShowSecret = (qna) => {
  if (checkWriteUser(qna)) return false
  if (qna.isSecret !== 1) return false

  return true
}

const delQna = async () => {
  if (!isWriteUser.value) return

  if (!confirm('정말로 삭제하시겠습니까?')) return

  const result = await deleteQna(qna.value.qnaId)
  if (result?.code) return alert(result.message)
  if (result.status) return alert(`Error: ${result.status}`)

  alert('삭제되었습니다.')
  routeReplace()
}

onMounted(async () => {
  isLoading.value = true

  boardData.value.boardId = route.params.id
  qna.value = await loadQna(boardData.value.boardId)

  isPassed.value = !checkShowSecret(qna.value)

  isLoading.value = false

  isWriteUser.value = checkWriteUser(qna.value)
})
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="wf__qna-section">
    <h2 class="wf__qna-title">QnA</h2>

    <div class="divider"></div>

    <article class="wf__qna" v-if="qna && isPassed">
      <h3 class="wf__community-default-title">{{ qna.title }}</h3>

      <ul class="wf__qna-desc">
        <li>{{ qna.date }}</li>
        <li>|</li>
        <li>{{ LanguageConverter({ en: 'views', kr: '조회' }) }} {{ numberFormat(qna.readCount) }}</li>
        <li>|</li>
        <li>{{ isShowQnaWriter(user, qna.userId) ? qna.userId : '***' }}</li>
        <li v-if="qna.isSecret">|</li>
        <li v-if="qna.isSecret"><span class="material-icons-outlined"> lock </span></li>
      </ul>

      <section class="wf__qna-content-section">
        <div class="wf__qna-content-wrapper question">
          <h5 class="wf__qna-content-title">{{ LanguageConverter({ en: 'Question', kr: '질문내용' }) }}</h5>
          <TextViewerVue :propsData="qna.content" :boardData="boardData"></TextViewerVue>
        </div>

        <div class="wf__qna-content-wrapper">
          <h5 class="wf__qna-content-title">{{ LanguageConverter({ en: 'Reply', kr: '질문답변' }) }}</h5>
          <TextViewerVue :propsData="qna.reply" :boardData="boardData"></TextViewerVue>
        </div>
      </section>
    </article>

    <article class="wf__qna my-5" v-if="qna && !isPassed">
      <h3 class="wf__community-default-title">비밀글입니다.</h3>

      <div>작성자와 관리자만 확인할 수 있습니다.</div>
    </article>

    <div class="divider"></div>

    <div class="wf__btn-wrapper">
      <span v-if="isWriteUser" class="wf__qna-btn danger" @click="delQna">
        {{ LanguageConverter({ en: 'Delete', kr: '삭제' }) }}
      </span>
      <span class="wf__qna-btn" @click="routeReplace">List</span>
    </div>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__qna-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  font-size: var(--wf-text-16);

  text-align: left;
  margin: 3rem;
}
.wf__qna-section-title {
  font-weight: bold;
}
.divider {
  height: 2px;
  background-color: var(--wf-gray-500);
  margin: 1rem 0;
}

.wf__qna {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.wf__qna-title {
  max-width: 25em;
  font-weight: 700;
  font-size: var(--wf-text-24);
}
.wf__qna-desc {
  display: flex;
  gap: 0.5em;
  color: var(--wf-secondary-color);

  margin-bottom: var(--wf-text-30);
}

.wf__qna-content-section {
  width: 100%;
}
.wf__qna-content-wrapper {
  padding: 1em 2em;
}
.wf__qna-content-wrapper.question {
  background-color: rgba(24, 161, 233, 0.15);
}
.wf__qna-content-title {
  font-weight: bold;
  color: var(--wf-primary-color);
  font-size: var(--wf-text-18);
}

.wf__btn-wrapper {
  display: flex;
  gap: 1em;
  justify-content: flex-end;
}
.wf__qna-btn.danger {
  color: var(--wf-error-color);
}
.wf__qna-btn {
  font-size: 1em;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #fff;
  background-color: var(--wf-primary-color);
  float: right;
  cursor: pointer;
  border-radius: 0.25em;
  padding: 0.35em 1em;
}
.wf__qna-btn:hover {
  opacity: 0.75;
}

.wf__qna-check-password-confirm {
  padding: 0.25em 1em;
  outline: none;
  border: none;
  border-radius: 0.25em;
  color: var(--wf-misty-color);
  background-color: var(--wf-primary-color);
  transition: opacity 0.2s ease;
}

.wf__qna-check-password {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.wf__qna-check-password-confirm:hover {
  opacity: 0.75;
}

.wf__qna-check-password-input {
  padding: 0.25em 0.5em;
  width: 100%;
}
</style>
